/* CONSTS.tsx
This file is for sharing constants that across multiple files.
*/
export enum UserSettingsActionType {
    GetUserSettings,
    PutUserSettings,
    DeleteUserSettings,
    UpdateFontSize,
    UpdateFontStyle,
    UpdateShellType,
    UpdateNetworkType,
    UpdateSessionType
}

export const consoleApiVersion = '2023-02-01-preview';
export const armApiVersion = '2018-07-01';
export const armApiversion2022 = '2022-12-01';
export const storageApiVersion = '2022-09-01';
export const feedbackLink = "https://aka.ms/cloudshell/feedback";
export const osTypeSelection = "linux";
export const cloudShellStorageString = "cloud-shell-storage";
export const timeoutDuration = 1000;
export const delta = 200;

export const maxUploadSize = 104857600;
export const fileShareNameLength = 63;
export const ResourceGroupNotFound = "ResourceGroupNotFound";
export const ResourceNotFound = "ResourceNotFound";
export const MissingSubscriptionRegistration = "MissingSubscriptionRegistration";
export const Linux = "linux";
export const timeoutForGetStorageAccount = 3000;
export const maxDropdownListSize = 500;

export const enum TerminalPrompt {
    PowerShell = "PS /home",
    Bash = "$ "
}

export const enum ResourceProviderNamespace {
    Storage = "Microsoft.Storage",
    CloudShell = "Microsoft.CloudShell"
}

export const enum RetryLimit {
    RequestRetryLimit = 3,
    GetProviderRegistrationState = 8,
    GetProviderRegistrationStateWhileRegistering = 20,
    GetStorageAccount  = 20
}

export const SwitchToV1MethodEnum = {
    VNetAndEphemeral: "VNetAndEphemeral",
    VNetAndRegular: "VNetAndRegular",
    EditorButton: "editorButton",
    EditorCommand: "editorCommand"
}

export const DefaultLocation = {
    Name: "westus",
    Code: "4"
}

export const BackgroundColors = {
    Bash: "#000000",
    PowerShell: "#012456",
    Drag: "#4c4c4c",
}

export const enum TelemetryLoggerEnum {
    ConsoleGet_Failure = 'CONSOLE.GET.FAILURE',
    ConsolePut_Failure = 'CONSOLE.PUT.FAILURE',
    Editor_Close = 'EDITOR.CLOSE',
    Editor_Load = 'EDITOR.LOAD',
    Editor_Load_Failure = 'EDITOR.LOAD.FAILURE',
    Editor_Open = 'EDITOR.OPEN',
    Editor_Save = 'EDITOR.SAVE',
    Editor_Save_Failure = 'EDITOR.SAVE.FAILURE',
    Feedback = 'FEEDBACK',
    Onboarding_Ephemeral = 'ONBOARDING.EPHEMERAL',
    Onboarding_Mounted = 'ONBOARDING.MOUNTED',
    Onboarding_StorageCreation = 'ONBOARDING.STORAGECREATION',
    SubscriptionRegistration_Invalid = 'SUBSCRIPTIONREGISTRATION.INVALID',
    Terminal_Connect_Success = 'TERMINAL.CONNECT.SUCCESS',
    Token_Invalid = 'TOKEN.INVALID',
    Token_Send = 'TOKEN.SEND',
    V1OrV2_GoToV1 = 'V1ORV2.GOTOV1',
    Window_Controls = 'WINDOW.CONTROLS',
    PutConsole_Success = "CONSOLE.PUT.SUCCESS",
    PutConsole_Timeout = "CONSOLE.PUT.TIMEOUT",
    TerminalVnet_Failure = "TERMINAL.VNET.FAILURE",
    TerminalConnect_Timeout = "TERMINAL.CONNECT.TIMEOUT",
    TerminalVnet_Open = "TERMINAL.VNET.OPEN",
    TerminalSocket_Close = "TERMINAL.SOCKET.CLOSE",
    Terminal_Close = "TERMINAL.CLOSE",
    Terminal_Open = 'TERMINAL.OPEN'
}

export const enum RequestLoggerEnum {
    Authorize = 'AUTHORIZE',
    BackfillSettings_Failure = 'BACKFILLSETTINGS.FAILURE',
    BackfillSettings_Success = 'BACKFILLSETTINGS.SUCCESS',
    CloudshellProviderRegistration_Failure = 'CLOUDSHELLPROVIDERREGISTRATION.FAILURE',
    CloudshellProviderRegistration_Success = 'CLOUDSHELLPROVIDERREGISTRATION.SUCCESS',
    CloudshellProviderVerification_Failure = 'CLOUDSHELLPROVIDERVERIFICATION.FAILURE',
    CloudshellProviderVerification_Success = 'CLOUDSHELLPROVIDERVERIFICATION.SUCCESS',
    Console_Get = 'CONSOLE.GET',
    Console_Put = 'CONSOLE.PUT',
    Editor_Load = 'EDITOR.LOAD',
    Editor_Save = 'EDITOR.SAVE',
    FileShare_List = 'FILESHARES.LIST',
    KeepAlive = 'KEEPALIVE',
    NetworkProfile_List = 'NETWORKPROFILES.LIST',
    Post_Cert = 'POST.CERT',
    Post_Token = 'POST.TOKEN',
    PutUserSettings_Failure = 'PUTUSERSETTINGS.FAILURE',
    PutUserSettings_Success = 'PUTUSERSETTINGS.SUCCESS',
    RelayNamespace_List = 'RELAYNAMESPACES.LIST',
    ResourceGroup_Create = 'RESOURCEGROUP.CREATE',
    ResourceGroup_Get = 'RESOURCEGROUP.GET',
    ResourceGroup_List = 'RESOURCEGROUP.LIST',
    ResourceProvider_Get = 'RESOURCEPROVIDER.GET',
    ResourceProvider_Register = 'RESOURCEPROVIDER.REGISTER',
    StorageAccount_Create = 'STORAGEACCOUNT.CREATE',
    StorageAccount_Get = 'STORAGEACCOUNT.GET',
    StorageAccount_List = 'STORAGEACCOUNT.LIST',
    StorageAccount_Put_Failure = 'STORAGEACCOUNT.PUT.FAILURE',
    StorageAccount_Put_Timeout = 'STORAGEACCOUNT.PUT.TIMEOUT',
    Subscription_List = 'SUBSCRIPTIONS.LIST',
    Terminal_Post = 'TERMINAL.POST',
    Terminal_Resize = 'TERMINAL.RESIZE',
    Terminal_Restart = "TERMINAL.RESTART",
    UserSettings_Get = 'USERSETTINGS.GET',
    VirtualNetwork_List = 'VIRTUALNETWORKS.LIST',
}

export const tokenAudiences = new Map<string, string>([
    // Universal
    ["https://graph.windows.net/", "graph"],
    ["https://storage.azure.com/", "storage"],
    ["https://www.yammer.com", "yammer"],
    ["https://azure-devices-provisioning.net", "devicesprovisioning"],
    ["6dae42f8-4368-4678-94ff-3960e28e3630", "kubelogin"],
    // AzureCloud
    ["https://management.core.windows.net/", ""],
    ["https://management.azure.com/", ""],
    ["https://pki.azure.net", "https://pki.azure.net"],
    ["https://vault.azure.net", "keyvault"],
    ["cfa8b339-82a2-471a-a3c9-0fc0be7a4093", "keyvault"],
    ["https://datalake.azure.net/", "datalake"],
    ["https://digitaltwins.azure.net", "digitaltwins01"],
    ["0b07f429-9f4b-4714-9392-cc5e8e80c8b0", "digitaltwins02"],
    ["https://outlook.office365.com/", "office365"],
    ["https://graph.microsoft.com/", "microsoft.graph"],
    ["00000003-0000-0000-c000-000000000000", "microsoft.graph"],
    ["https://batch.core.windows.net/", "azurebatch"],
    ["https://analysis.windows.net/powerbi/api", "powerbi"],
    ["https://rest.media.azure.net", "media"],
    ["https://api.loganalytics.io", "loganalytics"],
    ["https://ossrdbms-aad.database.windows.net", "ossrdbms"],
    ["822c8694-ad95-4735-9c55-256f7db2f9b4", "iotplugplay"],
    ["https://dev.azuresynapse.net", "azuresynapse"],
    ["https://database.windows.net", "database"],
    ["https://quantum.microsoft.com", "microsoft.quantum"],
    ["https://iothubs.azure.net", "iothub"],
    ["https://azuredatabricks.net/", "databricks1"],
    ["2ff814a6-3304-4ab8-85cb-cd0e6f879c1d", "databricks2"],
    ["ce34e7e5-485f-4d76-964f-b3d2b16d1e4f", "azuregrafana"],
    ["https://managedhsm.azure.net", "managedhsm"],
    ["499b84ac-1321-427f-aa17-267ca6975798", "devops"],
    ["https://api.adu.microsoft.com/", "deviceupdate"],
    ["https://purview.azure.net/", "purview"],
    ["https://devcenter.azure.com/", "https://devcenter.azure.com/"],
    ["https://azconfig.io/", "https://azconfig.io/"],
    // ["https://cognitiveservices.azure.com", "https://cognitiveservices.azure.com"],
    // AzureUSGovernment
    ["https://management.core.usgovcloudapi.net/", ""],
    ["https://management.usgovcloudapi.net/", ""],
    ["https://vault.usgovcloudapi.net", "keyvault"],
    ["https://datalake.usgovcloudapi.net/", "datalake"],
    ["https://outlook.office365.us/", "office365"],
    ["https://graph.microsoft.us/", "microsoft.graph"],
    ["https://batch.core.usgovcloudapi.net/", "azurebatch"],
    ["https://analysis.usgovcloudapi.net/powerbi/api", "powerbi"],
    ["https://rest.media.usgovcloudapi.net", "media"],
    ["https://api.loganalytics.us", "loganalytics"],
    ["https://ossrdbms-aad.database.usgovcloudapi.net", "ossrdbms"],
    ["https://dev.azuresynapse.usgovcloudapi.net", "azuresynapse"],
    ["https://database.usgovcloudapi.net/", "database"],
    ["https://managedhsm.usgovcloudapi.net", "managedhsm"],
    // AzureUSNat
    ["https://management.core.eaglex.ic.gov/", ""],
    ["https://management.azure.eaglex.ic.gov/", ""],
    ["https://graph.cloudapi.eaglex.ic.gov/", "graph"],
    ["https://vault.cloudapi.eaglex.ic.gov", "keyvault"],
    ["https://datalake.cloudapi.eaglex.ic.gov/", "datalake"],
    ["https://outlook.office365.eaglex.ic.gov/", "office365"],
    ["https://graph.eaglex.ic.gov/", "microsoft.graph"],
    ["https://batch.core.eaglex.ic.gov/", "azurebatch"],
    ["https://analysis.cloudapi.eaglex.ic.gov/powerbi/api", "powerbi"],
    ["https://storage.azure.eaglex.ic.gov/", "storage"],
    ["https://rest.media.cloudapi.eaglex.ic.gov", "media"],
    ["https://api.loganalytics.azure.eaglex.ic.gov", "loganalytics"],
    ["https://ossrdbms-aad.database.database.cloudapi.eaglex.ic.gov", "ossrdbms"],
    // AzureUSSec
    ["https://management.core.microsoft.scloud/", ""],
    ["https://management.azure.microsoft.scloud/", ""],
    ["https://graph.cloudapi.microsoft.scloud/", "graph"],
    ["https://vault.cloudapi.microsoft.scloud", "keyvault"],
    ["https://datalake.cloudapi.microsoft.scloud/", "datalake"],
    ["https://outlook.office365.microsoft.scloud/", "office365"],
    ["https://graph.microsoft.scloud/", "microsoft.graph"],
    ["https://batch.core.microsoft.scloud/", "azurebatch"],
    ["https://analysis.cloudapi.microsoft.scloud/powerbi/api", "powerbi"],
    ["https://storage.azure.microsoft.scloud/", "storage"],
    ["https://rest.media.cloudapi.microsoft.scloud", "media"],
    ["https://api.loganalytics.azure.microsoft.scloud", "loganalytics"],
    ["https://ossrdbms-aad.database.database.cloudapi.microsoft.scloud", "ossrdbms"]
]);

export const StorageData = {
    SKU: "Standard_LRS",
    Kind: "StorageV2",
    Tags: {
        Key: "ms-resource-usage",
        Value: "azure-cloud-shell"
    },
    KeySource: "Microsoft.Storage",
    MinimumTlsVersion: "TLS1_2"
}

export const FileStorage = { 
    Performance: {
        Standard: "Standard",
        Premium: "Premium"
    },
    AccountType : {
        BlockBlob: "BlockBlobStorage",
        FileStorage: "FileStorage",
        PageBlob: "StorageV2"
    },
}

export const BlobStorage = "BlobStorage";

export const validAudienceList = [
    "", 
    "arm", 
    "graph",
    "https://pki.azure.net", 
    "keyvault", 
    "datalake", 
    "office365", 
    "azurebatch", 
    "microsoft.graph", 
    "powerbi", 
    "storage", 
    "media", 
    "loganalytics", 
    "ossrdbms", 
    "yammer", 
    "digitaltwins01", 
    "digitaltwins02", 
    "iotplugplay", 
    "azuresynapse", 
    "database", 
    "microsoft.quantum", 
    "iothub", 
    "ssh1", 
    "ssh2", 
    "databricks1", 
    "databricks2", 
    "azuregrafana", 
    "devicesprovisioning", 
    "managedhsm",
    "devops", 
    "deviceupdate", 
    "purview",
    "https://devcenter.azure.com/",
    "https://azconfig.io/",
    "6dae42f8-4368-4678-94ff-3960e28e3630",
    "kubelogin",
    "cfa8b339-82a2-471a-a3c9-0fc0be7a4093",
    "00000003-0000-0000-c000-000000000000",
    "e526e72f-ffae-44a0-8dac-cf14b8bd40e2",
    "35ffadb3-7fc1-497e-b61b-381d28e744cc"
];

export const allowedParentFrameAuthorities = [
    "localhost:3000", 
    "portal.azure.com", 
    "portal.azure.us", 
    "rc.portal.azure.com", 
    "ms.portal.azure.com", 
    "docs.microsoft.com", 
    "review.docs.microsoft.com", 
    "ppe.docs.microsoft.com", 
    "ux.console.azure.us", 
    "admin-local.teams.microsoft.net", 
    "admin-ignite.microsoft.com", 
    "wusportalprv.office.com", 
    "portal-sdf.office.com", 
    "ncuportalprv.office.com", 
    "admin.microsoft.com", 
    "portal.microsoft.com", 
    "portal.office.com", 
    "admin.microsoft365.com", 
    "admin-sdf.exchange.microsoft.com", 
    "admin.exchange.microsoft.com", 
    "cloudconsole-ux-prod-usnatwest.appservice.eaglex.ic.gov", 
    "cloudconsole-ux-prod-usnateast.appservice.eaglex.ic.gov", 
    "portal.azure.eaglex.ic.gov", 
    "cloudconsole-ux-prod-ussecwest.appservice.microsoft.scloud", 
    "cloudconsole-ux-prod-usseceast.appservice.microsoft.scloud", 
    "portal.azure.microsoft.scloud", 
    "admin-local.teams.microsoft.net", 
    "admin-dev.teams.microsoft.net", 
    "admin-int.teams.microsoft.net", 
    "admin.teams.microsoft.com", 
    "preview.portal.azure.com", 
    "learn.microsoft.com", 
    "review.learn.microsoft.com", 
    "ppe.learn.microsoft.com", 
    "dev.learn.microsoft.com",
    "admin.cloud.microsoft",
    "ignite.admin.cloud.microsoft",
    "sdf.admin.cloud.microsoft",
    "canary.admin.cloud.microsoft",
    "admin.cloud.dev.microsoft",
    "ppe.admin.cloud.dev.microsoft"
];

type StorageLocationMappingType = {
    [key: string]: string;
};

// Maps storage locations to valid locations for use in userSettings.
export const storageLocationMappingProd: StorageLocationMappingType = {
    "southeastasia": "southeastasia",
    "australiasoutheast": "southeastasia",
    "koreacentral": "southeastasia",
    "koreasouth": "southeastasia",
    "australiaeast": "southeastasia",
    "australiacentral": "southeastasia",
    "japanwest": "southeastasia",
    "japaneast": "southeastasia",
    "eastasia": "southeastasia",
    "eastus": "eastus",
    "eastus2": "eastus",
    "canadaeast": "eastus",
    "brazilsouth": "eastus",
    "westus": "westus",
    "westus2": "westus",
    "westus3": "westus",
    "canadacentral": "westus",
    "southcentralus": "southcentralus",
    "northcentralus": "southcentralus",
    "centralus": "southcentralus",
    "westcentralus": "southcentralus",
    "northeurope": "northeurope",
    "germanywestcentral": "northeurope",
    "switzerlandnorth": "northeurope",
    "norwayeast": "northeurope",
    "jioindiawest": "centralindia",
    "westeurope": "westeurope",
    "uksouth": "westeurope",
    "ukwest": "westeurope",
    "francecentral": "westeurope",
    "centralindia": "centralindia",
    "southindia": "centralindia",
    "westindia": "centralindia",
    "southafricanorth": "centralindia",
    "swedencentral": "northeurope",
    "uaenorth": "centralindia",
    "qatarcentral": "centralindia",
    "mexicocentral": "southcentralus",
    "spaincentral": "westeurope"
};

export const storageLocationMappingGov: StorageLocationMappingType = {
    "usgovvirginia": "usgovvirginia",
    "usgovarizona": "usgovarizona"
};
  
export const storageLocationMappingNat: StorageLocationMappingType = {
    "usnatwest": "usnatwest",
    "usnateast": "usnateast"
};
  
export const storageLocationMappingSec: StorageLocationMappingType = {
    "ussecwest": "ussecwest",
    "usseceast": "usseceast"
};

export const enum CloudName {
    Prod = "prod",
    MPac = "mpac",
    RC = "rc",
    Fairfax = "fairfax",
    USNat = "USNat",
    USSec = "USSec",
    Other = "other"
}

export const storageLocationMap = new Map<string, StorageLocationMappingType>([
    [CloudName.Prod, storageLocationMappingProd],
    [CloudName.Fairfax, storageLocationMappingGov],
    [CloudName.USNat, storageLocationMappingNat],
    [CloudName.USSec, storageLocationMappingSec],
]);

// Maps high cost storage locations to low cost locations for regional demand shaping nudges
export const storageLocationCostMapping = new Map<string, string>([
    ["westeurope", "swedencentral"],
]);

export const enum FontStyle {
    Courier = "courier",
    Inconsolata = "inconsolata",
    Monospace = "monospace",
    Lucida = "lucida",
    Ptmono = "ptmono"  
}

export const FontStyleMap = new Map<string, string>([
    [FontStyle.Courier, "Courier New, courier, monospace"],
    [FontStyle.Inconsolata, "Inconsolata, monospace"],
    [FontStyle.Lucida, "Lucida Console, Monaco, monospace"],
    [FontStyle.Monospace, "monospace"],
    [FontStyle.Ptmono, "PT Mono, monospace"],
]);

export const enum FontSize {
    Small = "small",
    Medium = "medium",
    Large = "large"
}

export const FontSizeMap = new Map<string, number>([
    [FontSize.Small, 13],
    [FontSize.Medium, 16],
    [FontSize.Large, 21]
]);

export const enum ShellType {
    Bash = "bash",
    PowerShell = "pwsh",
}

export const enum MountState {
    Hidden,
    OSSelect,
    SubscriptionOnly,
    IntermediateMount,
    AdvancedMount,
    StorageCreation,
    Error,
    DeploymentInProgress,
    SubscriptionListError,
    SwitchToV1,
}

export const enum NetworkType {
    Isolated = "Isolated",
    Default = "Default"
}

export const enum MountedOption {
    ExistingStorageAccount = "ExistingStorageAccount",
    AutoCreation = "AutoCreation",
    NewStorageAccount = "NewStorageAccount",
    EphemeralVNET = "EphemeralVNET",
    MountedVNET = "MountedVNET"
}

export const enum SessionType {
    NotSpecified = "NotSpecified",
    Ephemeral = "Ephemeral",
    Mounted = "Mounted"
}

export const enum FileStatus {
    Success,
    UnknownFailure,
    TooLarge
}

export const ConnectionState = {
    NotConnected: 0,
    Connecting: 1,
    Connected: 2
};

export const enum DownloadError {
    ResolvePathFailed = "ResolvePathFailed",
    NotInAllowedDirectory = "NotInAllowedDirectory"
}

export const enum LocalStorageKey {
    CurrentOpenPort = "currentOpenPort",
    InjectedCommands = "injectedCommands",
    VirtualNetwork = "virtualNetwork",
    ScreenReaderMode = "screenReaderMode",
    FeedbackProvided = "feedbackProvided"
}

export const enum ProvisioningState {
    Succeeded = "Succeeded",
    Failed = "Failed"
}

export const enum ControlSocketAudience {
    Download = "download",
    Editor = "editor",
    Token = "token",
    Cert = "cert",
    Url = "url"
}

export const enum HttpMethod {
    Get = "get",
    Put = "put",
    Delete = "delete",
    Post = "post"
}

export const enum SubscriptionState {
    Enabled = "Enabled",
    Disabled = "Disabled",
    Deleted = "Deleted",
}

export const enum FeedbackType {
    None = 'none',
    Like = 'like',
    Dislike = 'dislike',
    NoFeedbackOnClose = "noFeedbackOnClose",
    DetailedFeedbackClicked = "detailedFeedbackClicked",
}

export const enum TerminalError {
    SubscriptionNotEnabled = "SubscriptionNotEnabled",
    TooManyTerminals = "TooManyTerminals",
    UserSettingsInvalid = "UserSettingsInvalid"
}

export const enum PostMessageHelperType {
    Maximize = "maximize",
    Minimize = "minimize",
    Restore = "restore",
    Close = "close",
    GetToken = "getToken",
    GetConfig = "getConfig",
    GetCommand = "getCommand",
    OpenFileShare = "openFileShare"
}

export const enum HostLocation {
    Prod = "ux.console.azure.com",
    MPac = "ux-mpac.console.azure.com",
    RC = "ux-rc.console.azure.com",
    Fairfax = "ux.console.azure.us",
    USNat = "ux.console.azure.eaglex.ic.gov",
    USSec = "ux.console.azure.microsoft.scloud"
}

export const enum ARMUrl {
    Public = "https://management.azure.com",
    Fairfax = "https://management.usgovcloudapi.net",
    USNat = "https://management.azure.eaglex.ic.gov",
    USSec = "https://management.azure.microsoft.scloud"
}

export const enum PortalUrl {
    Public = "https://portal.azure.com",
    Fairfax = "https://portal.azure.us",
    USNat = "https://portal.azure.eaglex.ic.gov",
    USSec = "https://portal.azure.microsoft.scloud"
}

export const allowedCommandsForInjection = ["az", "kubectl", "echo", "set", "touch", "chmod", "Start-AzVM", "Get-AzVMExtension", "Get-AzVM", "wget", "mkdir", "mysql", "psql", "unzip", "go", "zip", "./aztfy", "helm", "clear", "git", "pip3", "python3", "kubelogin"]

export type Filter = string | undefined;

export const enum FieldType {
    noOption = 0,
    filter = 1,
}

export const enum RegionalDisplayName {
    AsiaPacific = "asiaPacificRegionalDisplayName",
    US = "usRegionalDisplayName",
    Africa = "africaRegionalDisplayName",
    Europe = "europeRegionalDisplayName",
    SouthAmerica = "southAmericaRegionalDisplayName",
    Canada="canadaRegionalDisplayName",
    MiddleEast="middleEastRegionalDisplayName",
    Mexico = "mexicoRegionalDisplayName"
}

export const locationListProd = [
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "southeastasiaLocationName",
        name: "southeastasia",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "eastusLocationName",
        name: "eastus",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "westusLocationName",
        name: "westus",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "southcentralusLocationName",
        name: "southcentralus",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "northeuropeLocationName",
        name: "northeurope",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "westeuropeLocationName",
        name: "westeurope",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "centralindiaLocationName",
        name: "centralindia",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "eastasiaLocationName",
        name: "eastasia",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "centralusLocationName",
        name: "centralus",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "eastus2LocationName",
        name: "eastus2",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "northcentralusLocationName",
        name: "northcentralus",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "japanwestLocationName",
        name: "japanwest",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "japaneastLocationName",
        name: "japaneast",
    },
    {
        regionalDisplayName: RegionalDisplayName.SouthAmerica,
        displayName: "brazilsouthLocationName",
        name: "brazilsouth",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "australiaeastLocationName",
        name: "australiaeast",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "australiasoutheastLocationName",
        name: "australiasoutheast",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "southindiaLocationName",
        name: "southindia",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "westindiaLocationName",
        name: "westindia",
    },
    {
        regionalDisplayName: RegionalDisplayName.Canada,
        displayName: "canadacentralLocationName",
        name: "canadacentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Canada,
        displayName: "canadaeastLocationName",
        name: "canadaeast",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "uksouthLocationName",
        name: "uksouth",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "ukwestLocationName",
        name: "ukwest",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "westcentralusLocationName",
        name: "westcentralus",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "westus2LocationName",
        name: "westus2",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "westus3LocationName",
        name: "westus3",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "koreacentralLocationName",
        name: "koreacentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "koreasouthLocationName",
        name: "koreasouth",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "australiacentralLocationName",
        name: "australiacentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "francecentralLocationName",
        name: "francecentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "norwayeastLocationName",
        name: "norwayeast",
    },
    {
        regionalDisplayName: RegionalDisplayName.AsiaPacific,
        displayName: "jioindiawestLocationName",
        name: "jioindiawest",
    },
    {
        regionalDisplayName: RegionalDisplayName.Africa,
        displayName: "southafricanorthLocationName",
        name: "southafricanorth",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "germanywestcentralLocationName",
        name: "germanywestcentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "switzerlandnorthLocationName",
        name: "switzerlandnorth",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "swedencentralLocationName",
        name: "swedencentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.MiddleEast,
        displayName: "uaenorthLocationName",
        name: "uaenorth",
    },
    {
        regionalDisplayName: RegionalDisplayName.MiddleEast,
        displayName: "qatarcentralLocationName",
        name: "qatarcentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Mexico,
        displayName: "mexicocentralLocationName",
        name: "mexicocentral",
    },
    {
        regionalDisplayName: RegionalDisplayName.Europe,
        displayName: "spainCentralLocationName",
        name: "spaincentral",
    },
];

export const locationListFairfax = [
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "usgovvirginiaLocationName",
        name: "usgovvirginia",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "usgovarizonaLocationName",
        name: "usgovarizona",
    },
]

export const locationListNat = [
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "usnateast",
        name: "usnateast",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "usnatwest",
        name: "usnatwest",
    },
]

export const locationListSec = [
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "usseceast",
        name: "usseceast",
    },
    {
        regionalDisplayName: RegionalDisplayName.US,
        displayName: "ussecwest",
        name: "ussecwest",
    },
]

export const locationListMap = new Map<string, any>([
    [CloudName.Prod, locationListProd],
    [CloudName.Fairfax, locationListFairfax],
    [CloudName.USNat, locationListNat],
    [CloudName.USSec, locationListSec],
]);

export const enum DropdownOptionKey {
    Filter = "Filter",
    Divider = "Divider",
    NoOptions = "NoOptions",
    RecommendedHeader = "RecommendedHeader",
    OtherHeader = "OtherHeader"
}

export const enum EditorOpenType { 
    Toolbar = "Toolbar",
    Terminal = "Terminal",
    TerminalWithFileName = "TerminalWithFileName"
}
