import React from "react";

interface EditorVisibleContextState {
    editorVisible: boolean;
    setEditorVisible: (visible: boolean) => void;
    openEditorWithFile: boolean;
    setOpenEditorWithFile: (open: boolean) => void;
}
const initialEditorVisibleState: EditorVisibleContextState = {
    editorVisible: false,
    setEditorVisible: () => {},
    openEditorWithFile: false,
    setOpenEditorWithFile: () => {},
};
export type EditorVisibleContextType = {
    editorVisibleState: EditorVisibleContextState
}
export const EditorVisibleContext = React.createContext<EditorVisibleContextType>({
    editorVisibleState: initialEditorVisibleState
});