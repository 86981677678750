/*
###########
# Toolbar #
###########

-   **Inputs**: Too many to list
-   **Purpose**: The `Toolbar` component contains menu options for the user. 
-   **To do**:
    -   Backend communication
-   **Notes**:
    -   If the port value is set to `0` then the web preview button will only display a single option. If it is set to a valid port number then it displays a sub dropdown menu.   
*/

// External Components
import * as React from "react";
import { CommandBar, registerIcons, Theme } from "@fluentui/react"; //fluent ui toolbar
import {
    ContextualMenuItemType,
    IContextualMenuItem,
    IContextualMenuItemProps,
    IContextualMenuItemRenderFunctions,
} from "@fluentui/react/lib/ContextualMenu"; //fluent ui toolbar items and div
import { initializeIcons } from "@fluentui/react/lib/Icons"; // icons
import {
    AzureThemeLight,
    AzureThemeDark,
    AzureThemeHighContrastLight,
    AzureThemeHighContrastDark,
} from "@fluentui/azure-themes";
import { FontSize, FontStyle, PostMessageHelperType, ShellType, feedbackLink, TelemetryLoggerEnum, SwitchToV1MethodEnum, EditorOpenType } from "../../common/consts";
import { getARMEndpoint, getFontSize, getFontStyle, getPortalUrl, goToV1, isCloudNonProd, postMessageHelper } from "../Util/Utilities";
import { useTranslation } from "react-i18next";
import { useContext, useMemo, useRef } from "react";
import { useLogger } from "../Util/Logger";
import { EditorVisibleContext } from "./Editor/EditorVisibleContext";
import { UserSettingsContext } from "../DataProvider/UserSettingsProvider";

// Interface arguments
interface ToolbarProps {
    // These are the call back functions and data from parent
    // State variables
    isRestarting: boolean;
    consoleUri: string;
    currentFontStyle: string;
    currentFontSize: string;
    currentShellType: ShellType;
    currentPort: number;
    currentTheme: Theme;
    isFullScreen: boolean;
    // Call back functions
    ClosePort: () => void;
    manageFileShare: () => void; // opens a link to users azure portal
    // toggle view functions
    toggleDownloadDialog: () => void;
    togglePortDialog: () => void;
    toggleResetUserDialog: () => void;
    toggleRestartDialog: () => void; // function for child to callback to parent
    toggleSwitchShellTypeDialog: () => void;
    toggleIsFullScreen: () => void;
    // callback functions continues
    handleFontStyle: (fontStyle: string) => void; // callback to parent with
    setTheme: (newTheme: Theme) => void;
    handleFontSize: (fontSize: string) => void;
    selectFile: (e: React.ChangeEvent<HTMLInputElement>) => void
    isToolbarFeatureDisabled: boolean;
    isManageFileShareHidden: boolean;
    handleSwitchToV1ForEditor: (method: string) => void;
    shouldMaximize: boolean;
    setShouldMaximize: (maximize: boolean) => void;
} 

initializeIcons(); // Initialize icons for toolbar

// Register the azure specific help
registerIcons({
    icons: {
        "question-circle": (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
                preserveAspectRatio="xMinYMin"
            >
                <path d="M10,2a8,8,0,1,1-8,8A8,8,0,0,1,10,2Zm0,1a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,10.5a.75.75,0,1,1-.75.75A.75.75,0,0,1,10,13.5Zm0-8a2.5,2.5,0,0,1,1.6513,4.3771l-.1546.1253-.2185.1631-.0876.0717a1.9678,1.9678,0,0,0-.156.1487A2.2156,2.2156,0,0,0,10.5,12a.5.5,0,0,1-1,0,3.2,3.2,0,0,1,.8054-2.2984,3.1172,3.1172,0,0,1,.3555-.3229l.2467-.1843.118-.1A1.5,1.5,0,1,0,8.5,8a.5.5,0,0,1-1,0A2.5,2.5,0,0,1,10,5.5Z" />
            </svg>
        ),
        "Swap": (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <polygon points="10.85 0.15 15.25 4.55 10.85 8.85 10.15 8.15 13.35 5.05 1.95 5.05 1.95 4.05 13.35 4.05 10.15 0.85 10.85 0.15" />
                <polygon points="5.85 7.85 2.65 11.05 13.95 11.05 13.95 12.05 2.65 12.05 5.85 15.15 5.15 15.85 0.75 11.55 5.15 7.15 5.85 7.85" />
            </svg>
        )
    },
});

const Toolbar = (toolbarProps: ToolbarProps) => {

    const { t } = useTranslation();
    const portalUrl = getPortalUrl();
    const { userSettingsState } = useContext(UserSettingsContext);
    const { editorVisibleState } = useContext(EditorVisibleContext);
    const setEditorVisible = editorVisibleState.setEditorVisible;
    const editorVisible = editorVisibleState.editorVisible;

    React.useEffect(() => {
        if(toolbarProps.shouldMaximize) {
            toolbarProps.toggleIsFullScreen();
            handleMaximizeClick();
            toolbarProps.setShouldMaximize(false);
        }
    }, [toolbarProps.shouldMaximize]);

    const proxyUri  = useMemo(() => {
        return toolbarProps.consoleUri + '/proxy/' + toolbarProps.currentPort + '/';
    }, [toolbarProps.consoleUri, toolbarProps.currentPort]);

    const fileUploadRef = useRef<HTMLInputElement>(null);
    // Used to render items which are links
    const onRenderContent: IContextualMenuItem["onRenderContent"] = (
        props: IContextualMenuItemProps,
        defaultRenderers: IContextualMenuItemRenderFunctions
    ) => (
        // Render the commandbar item in a different order
        <>
            {defaultRenderers.renderCheckMarkIcon(props)}
            {renderItemLink(props)}
            {defaultRenderers.renderItemIcon(props)}
            {defaultRenderers.renderSecondaryText(props)}
            {defaultRenderers.renderSubMenuIcon(props)}
        </>
    );

    function renderItemLink(_a: IContextualMenuItemProps) {
        // Render the entire button to be a link and switch order for text and icon
        const item = _a.item,
        classNames = _a.classNames;
        return React.createElement(
            "span",
            {
                className: classNames.label,
                style: {
                    color: toolbarProps.currentTheme.semanticColors.link, // change text colour to theme
                },
                role: "Link", // append the role `Link` to only the text
            },
            item.text
        );
    }

    // change text-decoration for links when hovered
    const linkCSS = `
    span[role="Link"]:hover {
        text-decoration:underline;
    }`;
    
    const portProp = () => {
        // conditional prop that depends on whether or not there is a set and open port
        if (toolbarProps.currentPort === 0) {
            //if there is not a currently connected port
            return {
                key: "webPreview",
                text: t("webPreview"),
                onClick: () => toolbarProps.togglePortDialog(),
                iconProps: { iconName: "DocumentSearch" },
                disabled: toolbarProps.isToolbarFeatureDisabled
            };
        }
        return {
            // if there is a connected port
            key: "webPreview",
            text: t("webPreview"),
            iconProps: { iconName: "DocumentSearch" },
            subMenuProps: {
                // adds drop down
                items: [
                    {
                        key: "previewPort",
                        text: t("previewPort") + " " + toolbarProps.currentPort,
                        title: t("previewPort") + " " + toolbarProps.currentPort,
                        onClick: () => window.open(proxyUri, "_blank"),
                    },
                    {
                        key: "closePort",
                        text: t("closePort") + " " + toolbarProps.currentPort,
                        title: t("closePort") + " " + toolbarProps.currentPort,
                        onClick: () => toolbarProps.ClosePort(),
                    },
                    {
                        key: "configurePort",
                        text: t("configurePort"),
                        title: t("configurePort"),
                        onClick: () => toolbarProps.togglePortDialog(),
                    },
                ],
            },
            disabled: toolbarProps.isToolbarFeatureDisabled
        };
    };

    const fileshareProp = () => {
        // conditional prop that depends whether there is a fileshare
        const menuProperties: IContextualMenuItem[] = [
            {
                key: "upload",
                text: t("upload"),
                onClick: () => fileUploadRef.current?.click(),
                iconProps: { iconName: "Upload" },
            },
            {
                key: "download",
                text: t("download"),
                onClick: () => toolbarProps.toggleDownloadDialog(),
                iconProps: { iconName: "Download" },
            },
            {
                key: "divider_1",
                itemType: ContextualMenuItemType.Divider,
            },
        ];
        if(!toolbarProps.isManageFileShareHidden) {
            menuProperties.push({
                key: "manageFileShare",
                text: t("openFileShare"),
                onClick: () => toolbarProps.manageFileShare(),
                target: "_blank",
                iconProps: { iconName: "OpenInNewTab" },
                onRenderContent
            });
        }
        return menuProperties;
    }

    const logger = useLogger(getARMEndpoint(), {});

    const handleMaximizeClick = () => {
        postMessageHelper(PostMessageHelperType.Maximize, '');
        logger.clientTelemetry(TelemetryLoggerEnum.Window_Controls, { "action": PostMessageHelperType.Maximize }, {}, 0);
    }

    return (
        <>
            <style>{linkCSS}</style>
            <form id="upload-form" name="upload-form" encType="multipart/form-data" style={{ display: "none" }}>
                <input type="file" ref={fileUploadRef} id="file-uploader" aria-label={t('upload')} name="file-upload-input" multiple onChange={toolbarProps.selectFile}/>
            </form>
            <CommandBar
                items={[
                    {
                        key: "switchConsole",
                        text:
                            t("switchTo") +
                            " " +
                            (toolbarProps.currentShellType === ShellType.PowerShell
                                ? t("bashSelectionOption")
                                : t("powershellSelectionOption")),
                        style: { minWidth: "12em", textAlign: "left" },
                        onClick: () => toolbarProps.toggleSwitchShellTypeDialog(),
                        iconProps: { iconName: "Swap" },
                        disabled: toolbarProps.isToolbarFeatureDisabled
                    },
                    {
                        key: "restart",
                        text: t("restartButtonTitle"),
                        onClick: () => toolbarProps.toggleRestartDialog(),
                        iconProps: { iconName: "Redo" },
                        disabled: toolbarProps.isRestarting
                    },
                    {
                        key: "uploaddownload",
                        text: t("manageFiles"),
                        iconProps: { iconName: "CloudImportExport" },
                        subMenuProps: {
                            items: fileshareProp(),
                        },
                        disabled: toolbarProps.isToolbarFeatureDisabled,
                    },
                    {
                        key: "newSession",
                        text: t("newSession"),
                        onClick: () => { window.open(portalUrl + "/#cloudshell/", "_blank") },
                        iconProps: { iconName: "MoveToFolder" },
                    },
                    {
                        key: "copy",
                        text: t("editor"),
                        onClick: () => {
                            if(isCloudNonProd()) {
                                logger.clientTelemetry(TelemetryLoggerEnum.Editor_Open, {triggerType: EditorOpenType.Toolbar}, {}, 0);
                                setEditorVisible(!editorVisible);
                            } else {
                                toolbarProps.handleSwitchToV1ForEditor(SwitchToV1MethodEnum.EditorButton);
                            }
                        },
                        iconProps: { iconName: "Edit" },
                        disabled: toolbarProps.isToolbarFeatureDisabled,
                    },
                    portProp(),
                    {
                        key: "settings",
                        text: t("settingsButtonTitle"),
                        iconProps: { iconName: "Settings" },
                        subMenuProps: {
                            items: [
                                {
                                    key: "textSize",
                                    text: t("textSizeButtonTitle"),
                                    iconProps: { iconName: "FontSize" },
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "smallText",
                                                text: t("smallOption"),
                                                title: t("smallOption"),
                                                style: { fontSize: getFontSize(FontSize.Small) },
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentFontSize === FontSize.Small,
                                                onClick: () =>
                                                    toolbarProps.handleFontSize(FontSize.Small),
                                            },
                                            {
                                                key: "mediumText",
                                                text: t("mediumOption"),
                                                title: t("mediumOption"),
                                                style: { fontSize: getFontSize(FontSize.Medium) },
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentFontSize ===
                                                    FontSize.Medium,
                                                onClick: () =>
                                                    toolbarProps.handleFontSize(FontSize.Medium),
                                            },
                                            {
                                                key: "largeText",
                                                text: t("largeOption"),
                                                title: t("largeOption"),
                                                style: { fontSize: getFontSize(FontSize.Large) },
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentFontSize === FontSize.Large,
                                                onClick: () =>
                                                    toolbarProps.handleFontSize(FontSize.Large),
                                            }, 
                                        ],
                                    },
                                },
                                {
                                    key: "font",
                                    text: t("textStyleButtonTitle"),
                                    iconProps: { iconName: "Font" },
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "monospace",
                                                text: t("monospaceOption"),
                                                title: t("monospaceOption"),
                                                style: { fontFamily: getFontStyle(FontStyle.Monospace) },
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentFontStyle ===
                                                    FontStyle.Monospace,
                                                onClick: () =>
                                                    toolbarProps.handleFontStyle(FontStyle.Monospace),
                                            },
                                            {
                                                key: "couriernew",
                                                text: t("courierOption"),
                                                title: t("courierOption"),
                                                style: {
                                                    fontFamily: getFontStyle(FontStyle.Courier),
                                                },
                                                isChecked:
                                                    toolbarProps.currentFontStyle ===
                                                    FontStyle.Courier,
                                                onClick: () =>
                                                    toolbarProps.handleFontStyle(FontStyle.Courier),
                                            },
                                            {
                                                key: "Lucida Console",
                                                text: t("lucidaConsoleOption"),
                                                title: t("lucidaConsoleOption"),
                                                style: { fontFamily: getFontStyle(FontStyle.Lucida) },
                                                isChecked:
                                                    toolbarProps.currentFontStyle ===
                                                    FontStyle.Lucida,
                                                onClick: () =>
                                                    toolbarProps.handleFontStyle(FontStyle.Lucida),
                                            },
                                            {
                                                key: "PTMono",
                                                text: t("ptMonoOption"),
                                                title: t("ptMonoOption"),
                                                style: { fontFamily: getFontStyle(FontStyle.Ptmono) },
                                                isChecked:
                                                    toolbarProps.currentFontStyle ===
                                                    FontStyle.Ptmono,
                                                onClick: () =>
                                                    toolbarProps.handleFontStyle(FontStyle.Ptmono),
                                            },
                                            {
                                                key: "Inconsolata",
                                                text: t("inconsolataOption"),
                                                title: t("inconsolataOption"),
                                                style: { fontFamily: getFontStyle(FontStyle.Inconsolata) },
                                                isChecked:
                                                    toolbarProps.currentFontStyle ===
                                                    FontStyle.Inconsolata,
                                                onClick: () =>
                                                    toolbarProps.handleFontStyle(FontStyle.Inconsolata),
                                            },
                                        ],
                                    },
                                },
                                {
                                    key: "theme",
                                    text: t("theme"),
                                    iconProps: { iconName: "Light" },
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "lightTheme",
                                                text: t("lightOption"),
                                                title: t("lightOption"),
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentTheme === AzureThemeLight,
                                                onClick: () =>
                                                    toolbarProps.setTheme(AzureThemeLight),
                                            },
                                            {
                                                key: "darkTheme",
                                                text: t("darkOption"),
                                                title: t("darkOption"),
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentTheme === AzureThemeDark,
                                                onClick: () =>
                                                    toolbarProps.setTheme(AzureThemeDark),
                                            },
                                            {
                                                key: "highContrastLightTheme",
                                                text: t("highContrastLightOption"),
                                                title: t("highContrastLightOption"),
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentTheme ===
                                                    AzureThemeHighContrastLight,
                                                onClick: () =>
                                                    toolbarProps.setTheme(
                                                        AzureThemeHighContrastLight
                                                    ),
                                            },
                                            {
                                                key: "highContrastDarkTheme",
                                                text: t("highContrastDarkOption"),
                                                title: t("highContrastDarkOption"),
                                                canCheck: true,
                                                isChecked:
                                                    toolbarProps.currentTheme ===
                                                    AzureThemeHighContrastDark,
                                                onClick: () =>
                                                    toolbarProps.setTheme(
                                                        AzureThemeHighContrastDark
                                                    ),
                                            },
                                        ],
                                    },
                                },
                                {
                                    key: "resetUserSettings",
                                    text: t("resetUserDialogHeader"),
                                    onClick: () => toolbarProps.toggleResetUserDialog(),
                                    iconProps: { iconName: "Refresh" },
                                },
                                {
                                    key: "goToV1Button",
                                    text: t("goToV1Button"),
                                    onClick: () => goToV1(userSettingsState.properties.userSubscription, toolbarProps.currentShellType),
                                    iconProps: { iconName: "Back" },
                                },
                            ],
                        },
                    },

                    {
                        key: "help",
                        text: t("helpButtonTitle"),
                        iconProps: { iconName: "question-circle", color: "#abcdef" }, // need to pading
                        icon: { color: "#00acda" },
                        subMenuProps: {
                            items: [
                                {
                                    key: "overview",
                                    text: t("overviewLink"),
                                    href: "https://aka.ms/cloudshell",
                                    target: "_blank",
                                    iconProps: { iconName: "OpenInNewTab" },
                                    onRenderContent,
                                },
                                {
                                    key: "cliRef",
                                    text: t("cliLink"),
                                    href: "https://aka.ms/azcli-docs",
                                    target: "_blank",
                                    iconProps: { iconName: "OpenInNewTab" },
                                    onRenderContent,
                                },
                                {
                                    key: "copyPaste",
                                    text: t("copypasteHelp"),
                                    href: "https://aka.ms/cloudshell/copypaste",
                                    target: "_blank",
                                    split: true,
                                    iconProps: { iconName: "OpenInNewTab" },
                                    onRenderContent,
                                },
                                {
                                    key: "divider_2",
                                    itemType: ContextualMenuItemType.Divider,
                                },
                                {
                                    key: "privacyTerms",
                                    text: t("privacyLink"),
                                    target: "_blank",
                                    split: true,
                                    href: "https://aka.ms/privacyterms",
                                    iconProps: { iconName: "OpenInNewTab" },
                                    onRenderContent,
                                },
                                {
                                    key: "sendFeedback",
                                    text: t("feedbackLink"),
                                    href: feedbackLink,
                                    target: "_blank",
                                    className: "Link",
                                    iconProps: { iconName: "OpenInNewTab" },
                                    onRenderContent,
                                },
                            ],
                        },
                    },
                ]}
                overflowItems={[]}
                overflowButtonProps={{ ariaLabel: t("toolbarOverflowAriaLabel") }}
                farItems={[
                    {
                        key: "minimize",
                        text: t("minimizeButton"),
                        // This needs an ariaLabel since it's icon-only
                        ariaLabel: t("minimizeButton"),
                        iconOnly: true,
                        iconProps: {
                            iconName: "ChromeMinimize",
                            style: {
                                color: toolbarProps.currentTheme.semanticColors.bodyText,
                            },
                        },
                        onClick: () => postMessageHelper(PostMessageHelperType.Minimize, ''),
                    },
                    {
                        key: "maximize",
                        text: toolbarProps.isFullScreen
                            ? t("restoreButton")
                            : t("maximizeButton"),
                        // This needs an ariaLabel since it's icon-only
                        ariaLabel: toolbarProps.isFullScreen
                            ? t("restoreButton")
                            : t("maximizeButton"),
                        iconOnly: true,
                        iconProps: {
                            iconName: toolbarProps.isFullScreen
                                ? "ChromeRestore"
                                : "ChromeFullScreen",
                            style: {
                                color: toolbarProps.currentTheme.semanticColors.bodyText,
                            },
                        },
                        onClick: () => {
                            toolbarProps.isFullScreen
                                ? postMessageHelper(PostMessageHelperType.Restore, '')
                                : handleMaximizeClick()
                            toolbarProps.toggleIsFullScreen();
                        },
                    },
                    {
                        key: "close",
                        text: t("closeButton"),
                        // This needs an ariaLabel since it's icon-only
                        ariaLabel: t("closeButton"),
                        iconOnly: true,
                        iconProps: {
                            iconName: "ChromeClose",
                            style: {
                                color: toolbarProps.currentTheme.semanticColors.bodyText,
                            },
                        },
                        onClick: () => postMessageHelper(PostMessageHelperType.Close, ''),
                    },
                ]}
                ariaLabel={t("toolbarAriaLabel")}
                primaryGroupAriaLabel={t("toolbarPrimaryGroupAriaLabel")}
                farItemsGroupAriaLabel={t("toolbarFarItemsGroupAriaLabel")}
            />
        </>
    );
};

export default Toolbar;
