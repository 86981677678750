import {Editor as MonacoEditor} from '@monaco-editor/react';
import React, { useContext, useState } from 'react';
import SaveDialog from "./Dialog/SaveDialog";
import { useBoolean } from "@uifabric/react-hooks";
import SaveBeforeExitDialog from "./Dialog/SaveBeforeExitDialog";
import { EditorVisibleContext } from "./EditorVisibleContext";
import { useTranslation } from "react-i18next";
import ErrorDialog from "../../Error/Error";
import { useAccessTokenContext } from "../../DataProvider/EventProvider";
import { IHttpClient } from "../../Util/HttpClient";
import { IEditorRef } from "./IEditorRef";
import OpenFileDialog from "./Dialog/OpenFileDialog";
import { getARMEndpoint } from "../../Util/Utilities";
import { useLogger } from "../../Util/Logger";
import { HttpMethod, RequestLoggerEnum, TelemetryLoggerEnum } from "../../../common/consts";
import { CorrelationIdContext } from "../../DataProvider/IdProviders";


interface EditorProps {
    height: string;
    saveClicked: boolean;
    saveAsClicked: boolean;
    resetSaveClicked: () => void;
    consoleUri: string;
    rootDirectory: string;
    onFileNameChange: (fileName: string) => void;
    setIsDirty: (IsDirty: boolean) => void;
    isDirty: boolean;
    setIsClosing: (isClosing: boolean) => void;
    isClosing: boolean;
    displayFileName: string;
    displayFilepath: string;
    axiosInstance: IHttpClient;
    editorRef: React.MutableRefObject<IEditorRef>;
    monacoRef: React.MutableRefObject<MonacoEditorRef>;
    openFileDialogVisible: boolean;
    resetOpenFileDialog: () => void;
    focusTerminal: (isTerminalFocused: boolean) => void;
}

export interface MonacoEditorRef {
    KeyCode: any;
    editor: {
        addEditorAction: (action: any) => void;
    }
    KeyMod: any;
  }

const Editor = (editorProps: EditorProps) => {
    const { t } = useTranslation();
    const editorVisibleContext = React.useContext(EditorVisibleContext);
    let fileUri: string;
    const [hideSaveDialog, { toggle: toggleSaveDialog }] = useBoolean(true);
    const [hideOpenFileDialog, { toggle: toggleOpenFileDialog }] = useBoolean(true);
    const [hideErrorDialog, setHideErrorDialog] = useState(true);
    const [errorMessages, setErrorMessages] = useState({header: '', content: ''});
    const [hideDirtyDialog, { toggle: toggleDirtyDialog }] = useBoolean(true);
    const [currentFileVersion, setCurrentFileVersion] = React.useState<string>("");
    const [currentFileUnsaved, setCurrentFileUnsaved] = React.useState<boolean>(true);
    const { editorVisibleState } = useContext(EditorVisibleContext);
    const setEditorVisible = editorVisibleState.setEditorVisible;
    const openEditorWithFile = editorVisibleState.openEditorWithFile;
    const setOpenEditorWithFile = editorVisibleState.setOpenEditorWithFile;
    const { accessToken } = useAccessTokenContext();
    const language = useTranslation().i18n.language;
    const [displayFileName, setDisplayFileName] = React.useState<string>(editorProps.displayFileName);

    const logger = useLogger(getARMEndpoint(), {});
    const { correlationId } = useContext(CorrelationIdContext);

    function closeEditor() {
        logger.clientTelemetry(TelemetryLoggerEnum.Editor_Close, {}, {}, 0, correlationId)
        setEditorVisible(false);
        editorProps.setIsClosing(false);
    }

    React.useEffect(() => {
        setEditorVisible(false);
    }, []);

    React.useEffect(() => {
        if (editorProps.saveAsClicked) {
            toggleSaveDialog();
            editorProps.resetSaveClicked();
        }
    }, [editorProps.saveAsClicked, editorProps.resetSaveClicked]);

    React.useEffect(() => {
        if(editorProps.saveClicked) {
            handleSave();
            editorProps.resetSaveClicked();
        }
    }, [editorProps.saveClicked, editorProps.resetSaveClicked]);

    React.useEffect(() => {
        if(editorProps.isClosing && editorProps.isDirty) {
            toggleDirtyDialog();
        }
        if(editorProps.isClosing && !editorProps.isDirty) {
            closeEditor();
        }
    }, [editorProps.isClosing, editorProps.isDirty]);

    React.useEffect(() => {
        if(displayFileName !== "" && openEditorWithFile && !editorProps.isDirty) {
            load(displayFileName);
            setOpenEditorWithFile(false);
        }else if(displayFileName !== "" && openEditorWithFile && editorProps.isDirty) {
            toggleDirtyDialog();
        }
    }, [openEditorWithFile]);

    React.useEffect(() => {
        if(editorProps.openFileDialogVisible) {
            toggleOpenFileDialog();
            editorProps.resetOpenFileDialog();
        }
    }, [editorProps.openFileDialogVisible]);

    React.useEffect(() => {
        setDisplayFileName(editorProps.displayFileName);
    }, [editorProps.displayFileName]);

    const handleSave = () => {
        if(displayFileName && displayFileName!== t("untitledFileHeader")) {
            save(displayFileName);
        } else {
            toggleSaveDialog();
        }
    }

    const handleSaveButton = () => {
        toggleSaveDialog();
        toggleDirtyDialog();
    };

    const handleDontSave = () => {
        if(editorProps.isClosing) {
            handleCloseNoSave();
        } else {    
            handleOpenNoSave();
        }
    };

    const handleCancel = () => {
        toggleDirtyDialog();
        if(editorProps.isClosing) {
            editorProps.setIsClosing(false);
        } else if(openEditorWithFile) {
            setOpenEditorWithFile(false);
        }
    };

    const handleCloseNoSave = () => {
        toggleDirtyDialog();
        closeEditor();
    };

    const handleOpenNoSave = () => {
        toggleDirtyDialog();
        load(displayFileName);
        editorProps.onFileNameChange(displayFileName);
        setOpenEditorWithFile(false);
    };

    function save(fileName: string) {
        logger.clientTelemetry(TelemetryLoggerEnum.Editor_Save, {}, {}, 0, correlationId);
        fileUri = `/files${editorProps.rootDirectory}/${fileName}`;
        if(typeof fileUri !== "undefined" && fileName !== "") {
            const headers =  {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
                'Accept-Language': language
            };
            const targetUri = `${editorProps.consoleUri}${fileUri}`;
            const start = Date.now();

            editorProps.axiosInstance.put(targetUri, editorProps.editorRef.current.getValue(), {headers: headers})
            .then((response: any) => {
                logger.clientRequest(RequestLoggerEnum.Editor_Save, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, response.status, correlationId);
                if(response.status === 200) {
                    setCurrentFileUnsaved(false);
                    editorProps.setIsDirty(false);
                }

            }).catch((error: any) => {
                logger.clientRequest(RequestLoggerEnum.Editor_Save, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, error.response?.status, correlationId);
                console.log("Error saving file!", error.response?.data);
                setHideErrorDialog(false);
                const destination = fileUri || fileUri.replace(fileName, "");
                setErrorMessages({
                    header: t("editorSaveErrorText"),
                    content: t("editorSaveErrorBody", {fileName: fileName, destination: destination.replace("/files", "")})
                });
            });
        } else {
            logger.clientTelemetry(TelemetryLoggerEnum.Editor_Save_Failure, {}, {}, 0, correlationId);
            console.log("File URI is undefined");
        }
    }

    function load(fileName: string) {
        logger.clientTelemetry(TelemetryLoggerEnum.Editor_Load, {}, {}, 0, correlationId);
        if(editorProps.displayFilepath === "") {
            fileUri = `/files${editorProps.rootDirectory}/${fileName}`;
        } else {
            fileUri = `${editorProps.displayFilepath}`;
        }
        const headers =  {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language
        };
        const targetUri = `${editorProps.consoleUri}${fileUri}`;
        const start = Date.now();

        editorProps.axiosInstance.get(targetUri, {headers: headers})
        .then((response: any) => {
            logger.clientRequest(RequestLoggerEnum.Editor_Load, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, response.status, correlationId);
            if(response.status === 200) {
                editorProps.editorRef.current.setValue(response.data);
                editorProps.onFileNameChange(fileName);
                setCurrentFileUnsaved(false);
                editorProps.setIsDirty(false);
                setEditorVisible(true);
            }
        }).catch((error: any) => {
            logger.clientRequest(RequestLoggerEnum.Editor_Load, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response?.status, correlationId);
            if(error.response?.status === 400) {
                editorProps.editorRef.current.setValue("");
                editorProps.onFileNameChange(fileName);
                setCurrentFileUnsaved(false);
                editorProps.setIsDirty(false);
                setEditorVisible(true);
             } else {
                logger.clientTelemetry(TelemetryLoggerEnum.Editor_Load_Failure, {}, {}, 0, correlationId);
                console.log("Error loading file!", error.response?.data);
                setHideErrorDialog(false);
                setErrorMessages({
                    header: t("editorOpenErrorText"),
                    content: t("editorOpenErrorBody", {fileName: fileName})
                });
             }
        });
    }

    if(editorProps.monacoRef.current) {
        const saveAction: any = {
            id: "save",
                label: "Save",
                keybindings: [editorProps.monacoRef.current.KeyMod.CtrlCmd | editorProps.monacoRef.current.KeyCode.KeyS],
                run: function() {
                    handleSave();
                }
        };

        const saveAsAction: any = {
            id: "saveAs",
            label: "Save As",
            keybindings: [editorProps.monacoRef.current.KeyMod.CtrlCmd | editorProps.monacoRef.current.KeyMod.Shift | editorProps.monacoRef.current.KeyCode.KeyS],
            run: function() {
                toggleSaveDialog();
            }
        };

        const closeEditorAction: any = {
            id: "closeEditor",
            label: "Close Editor",
            keybindings: [
                editorProps.monacoRef.current.KeyMod.CtrlCmd | editorProps.monacoRef.current.KeyCode.KeyQ
            ],
            run: function() {
                editorProps.setIsClosing(true);
            }
        };

        const focusTermainalAction: any = {
            id: "focusTerminal",
            label: "Focus Terminal",
            keybindings: [
                editorProps.monacoRef.current.KeyMod.CtrlCmd | editorProps.monacoRef.current.KeyCode.Backquote
            ],
            run: function() {
                editorProps.focusTerminal(true);
            }
        };

        const openFileDialogAction: any = {
            id: "openFile",
            label: "Open File",
            keybindings: [
                editorProps.monacoRef.current.KeyMod.CtrlCmd | editorProps.monacoRef.current.KeyCode.KeyP
            ],
            run: function() {
                toggleOpenFileDialog();
            }
        };

        editorProps.monacoRef.current.editor.addEditorAction(saveAction);
        editorProps.monacoRef.current.editor.addEditorAction(saveAsAction);
        editorProps.monacoRef.current.editor.addEditorAction(closeEditorAction);
        editorProps.monacoRef.current.editor.addEditorAction(focusTermainalAction);
        editorProps.monacoRef.current.editor.addEditorAction(openFileDialogAction);
    }

    function handleEditorDidMount(editor: any, monaco: any) {
        editor.focus();
        editorProps.editorRef.current = editor;
        editorProps.monacoRef.current = monaco;
        const model = editor.getModel();
        if (model) {
            setCurrentFileVersion(model.getAlternativeVersionId().toString());
        }
    }

    return (
        <div>
            <div data-testid="editor" />
            <MonacoEditor
                theme='vs-dark'
                height={editorProps.height || "80%"}
                onMount={handleEditorDidMount}
                onChange={() => editorProps.setIsDirty(true)}/>

            <SaveDialog
                hideSaveDialog={hideSaveDialog}
                toggleSaveDialog={toggleSaveDialog}
                onSave={(newFileName: string) => {
                    setDisplayFileName(newFileName);
                    save(newFileName);
                    editorProps.onFileNameChange(newFileName);
                    if(editorProps.isClosing) {
                        closeEditor();
                    } else if (openEditorWithFile) {
                        load(displayFileName);
                        setOpenEditorWithFile(false);
                        editorProps.onFileNameChange(displayFileName)
                    }
                }}
                onClose={() => {
                    if(editorProps.isClosing) {
                        setEditorVisible(false)
                        editorProps.setIsClosing(false);
                    }
                }}/>

            <ErrorDialog 
                hideErrorDialog={hideErrorDialog}
                quit={() => setHideErrorDialog(true)} 
                errorMessages={errorMessages}/>

                <SaveBeforeExitDialog
                    hideDialog={hideDirtyDialog}
                    toggleDialog={() => handleCancel()}
                    onSave={() => handleSaveButton()}
                    onDontSave={() => handleDontSave()}/>
                
                <OpenFileDialog
                    toggleOpenFileDialog={toggleOpenFileDialog}
                    hideOpenFileDialog={hideOpenFileDialog}
                    onClose={toggleOpenFileDialog}/>
            </div>
    );
};

export default Editor;
